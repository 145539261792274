<template>
  <div class="Poster">
    <div class="top-title">
      <div class="top-text">上传海报</div>
      
    </div>
                 <div class="top-box-image">
                    <span>上传图片</span>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="true"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      :action="upLoadUrl"
                      :data="headerMsg">
                      <img v-if="listpar.bgpic" :src="ImgUrl+listpar.bgpic" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div style="width:120px;margin:0 auto; color:red;">只能上传替换一张</div>

                <div class="top-box-box">
                    <el-button type="primary" class="biy-icon" @click="onSubmit()">提交</el-button>
                </div>
                <div class="div-img">
                    <img :src="ImgUrl+listpar.bgpic" alt="">
                </div>



  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
          ImgUrl:config.ImgUrl,
            // 上传图片的地址
      upLoadUrl:config.uploadingimg,
    
      headerMsg:{
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        uploadfile:'file',
      },
  
      listpar:{},
       list:[],
    };
  },
  created() {
      this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
   async getSong(){
 	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'PosterEntity',
        handleMode:'fetch',
         })
       
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.listpar = res.Result
      },

    async onSubmit(){
 	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'PosterEntity',
        handleMode:'upsert',
        bizData:{
             bgpic:this.listpar.bgpic
        }
         })
       
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('更新成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {}
        });
      },

          // 图片上传
    handleAvatarSuccess(res, file) {
        
     
      // 图片路径拼接
      this.listpar.bgpic = res.Result
    },

    beforeAvatarUpload() {
      console.log(" ");
    },

  },
  computed: {},
};
</script>


<style  scoped>
.top-box-box{
    width: 200px;
    margin: 0 auto;
}
.top-box-box .biy-icon{
    width: 200px;
}
/* 上传 */
.top-box-image {
  width: 200px;
  display: flex;
  justify-content: space-around;
  height: 100px;
  font-size: 14px;
  overflow: hidden;
  margin: 0px auto;
}
.top-box-image span{
  line-height: 78px;
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  border: 1px solid rgba(153, 153, 153, 0.637);
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  height: 78px;
  display: block;
}
.div-img{
    width: 100%;
    text-align: center;
    padding-top: 50px;
}
.div-img img{
    width: 300px;
}
.particulars-map{
  width: 100%;
  display: flex;
  justify-content: center;
}
@import "../../assets/css/liststyle.css";
</style>